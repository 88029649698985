/** @format */

import styled from "styled-components";

export const StyledFooter = styled.footer`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 40px clamp(22px, 3.208vw, 60px);
  background-color: #2f2f2d;
`;

export const StyledLeftWrapper = styled.div`
  width: 50%;
  max-width: 273px;

  @media only screen and (max-width: 616px) {
    width: 100%;
    max-width: unset;
  }
`;

export const StyledRightWrapper = styled.div`
  width: 50%;
  border-left: 1px solid #fff;
  padding-left: 40px;

  @media only screen and (max-width: 616px) {
    width: 100%;
    max-width: unset;
    border-left: none;
    padding-left: 0;
    margin-top: 20px;
    margin-bottom: 40px;
  }
`;

export const StyledLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: clamp(30px, 2.344vw, 45px);

  a {
    text-decoration: none;
    color: #fff;
    transition: color 250ms linear;

    &:hover {
      color: #0bc76d;
    }

    &:focus-visible {
      outline-width: 1px;
      outline-style: solid;
      outline-color: #0bc76d;
      outline-offset: 4px;
    }
  }
`;

export const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 616px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const StyledCoopyrightWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  font: 400 clamp(14px, 0.838vw, 16px) Poppins;
  color: #fff;
`;

export const StyledCertWrapper = styled.div`
  margin-top: 20px;
  width: 170%;
  background-color: #fff;

  img {
    width: 100%;
  }

  @media only screen and (max-width: 1050px) {
    width: 100%;
  }
`;
